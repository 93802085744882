.completed-task .MuiDataGrid-cell {
  text-decoration: line-through;
  color: red;
}

/* gamePage.css */
.max-width-container {
  width: 100%;
  max-width: 90%;
  margin: 0 auto;
}

.selected-column {
  background-color: #2962ff;
  /* Change the background color as desired */
  color: white;
  /* Change the text color as desired */
  border: none;
}

/* delete button style */
.icon-red-on-hover {
  cursor: pointer;
  color: inherit;
  transition: color 0.3s;
}

.icon-red-on-hover:hover {
  color: red;
}



.col-main-style {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px;
  font-size: 15px;
  font-weight: 800;
  height: 50px;
  cursor: pointer;
  padding: 0 10px;
}


.col-main-style>div {
  display: flex;
  align-items: center;
}

.col-child-text-style {
  display: inline;
  text-align: left;
}

.col-child-text-style>p:first-child {
  font-size: 13px;
  color: #797979;
}


/* CustomRewards.css */
.main-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
}

.main-div-child1 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.custom-each {
  flex: 1;
  padding: 0 20px;
}

.custom-each-child {
  display: flex;
  justify-content: space-between;
}

/* ViralMarketing.css */
.badge-success {
  background-color: green;
}

.badge-info {
  background-color: blue;
}

.badge-warning {
  background-color: yellow;
}

.badge-secondary {
  background-color: gray;
}

/* Pagination container */
.pagination-parent {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

/* Page number buttons */
.pagination a {
  display: inline-block;
  padding: 6px 12px;
  margin: 2px;
  background-color: #797979;
  color: #fff !important;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
}

/* Active page button */
.pagination a.active {
  background-color: #007bff !important;
  color: #fff !important;
}

/* Style for "In stock" status */
.in-stock {
  background-color: #C7FFD4 !important;
  color: #00B42A !important;
  text-align: center;

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  border-radius: 5px;
  padding: 10px;
}

/* Style for "Not available" status */
.not-available {
  /* Add styles for "Not available" status here */
}

.range-boxes {
  display: flex;
  flex-wrap: nowrap; 
  gap: 10px;
}
.range-box {
  cursor: pointer;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 10%;
}

.range-box.active {
  background-color: #007bff;
  color: #fff;
}




@media only screen and  (min-width: 575px) and (max-width: 850px){
  .range-box {
    width: calc(30.33% - 5px); 
  }
} 
 

@media only screen and (max-width: 575px) {
  
  .range-box {
    width: calc(30.33% - 5px); /* Adjust width to take up one-third of the space with gap included */
  }
  .range-boxes{
    flex-wrap: wrap;
  }

}
