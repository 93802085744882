
@media only screen and (max-width: 440px) {
    .pg-wrap {
      padding: 0 10px; /* Adjust padding for smaller screens */
    }
    .main-content {
      padding: 0; /* Remove padding for smaller screens */
    }
    .card{
      max-width: 20rem; /* Make cards full width for smaller screens */
      margin-bottom: 20px; /* Add some margin between cards */
    }
    .card-body {
      
      padding: 20px; /* Add padding inside card bodies */
    }
    .card-title{
      font-size: 15px;
      font-weight: 300;
    }
    .card-subtitle{
      font-size: 5px;
      font-weight: 300;
    }
    #msg{
      font-size: 13px;
    }
   
    .card-btn button {
      margin-left: 0; 
    }
  }
  @media only screen and (min-width: 450px) and (max-width: 767px){

    .pg-wrap {
      padding: 0 10px; /* Adjust padding for smaller screens */
    }
    .main-content {
      padding: 0; /* Remove padding for smaller screens */
    }
    .card{
      max-width: 30rem; /* Make cards full width for smaller screens */
      margin-bottom: 20px; /* Add some margin between cards */
    }
    .card-body {
      
      padding: 20px; /* Add padding inside card bodies */
    }
    .card-title{
      font-size: 15px;
      font-weight: 200;
    }
    .card-subtitle{
      font-size: 5px;
      font-weight: 300;
    }
    #msg{
      font-size: 13px;
      font-weight: 200px;
    }
   
    .card-btn button {
      margin-left: 0; /* Reset margin for buttons inside cards */
    }
  }
  