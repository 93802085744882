/*
Table Of Content
=================================
* Color system
* Body
* Typography
* Todos
* Calendar
* Notes
* Search
* Notifications
* Breadcrumbs
* Alerts
* Badges
* Buttons
* Cards
* Dropdowns
* Forms
* List Groups
* Modals
* Progress bars
* Tables
* Tabs
* Signup
* Login
* Forgot Password
*/

@import url('https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500,500i,700,700i,900,900i');
@import url(../css/LineIcons.min.css);
// JvectorMap CSS
@import url('https://cdnjs.cloudflare.com/ajax/libs/jvectormap/2.0.4/jquery-jvectormap.css');

/* Default color & font Variables */
$body-font-family: 'Roboto', sans-serif;
$body-bg-color: #f3f6f9;
$template-text-color: #47404f;
$primary-color: #2962ff;
$pragraph-color: #797979;
$border-color: #ebebee;
$danger-color: #ff3547;
$global-color: #47404f;
$gray-color: #a1aab2;
$purple-color: #7266ba;
$light-blue-bg: #42a5f6;
$success-color: #7ed320;
$danger-color: #f75d81;
/* End default color & font Variables */

/* Default style */
body {
    background-color: $body-bg-color;
    color: $template-text-color;
    font-size: .815rem;
    font-family: $body-font-family;
}
a {
    transition: .4s;
    -webkit-transition: .4s;
    text-decoration: none;
    &:hover {
        text-decoration: none;
        color: $primary-color;
    }
}
p {
    color: $pragraph-color;
    line-height: 24px;
}
img {
    max-width: 100%;
}
/* Scrollbar style */
::-webkit-scrollbar {
    width: 10px;
    height: 6px;
}
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px $border-color; 
    border-radius: 30px;
}
::-webkit-scrollbar-thumb { 
    border-radius: 30px;
    background: $primary-color; 
    box-shadow: inset 0 0 6px $border-color; 
}
::-webkit-scrollbar-thumb:window-inactive {
	background: $primary-color;
}
/* End Scrollbar style */
button:focus {
    outline: 0;
}
.btn {
    font-size: 13px;
    padding: 10px 15px;
    line-height: 1;
    border-radius: 4px;
}
.badge {
    padding-right: 10px;
    padding-left: 10px;
    font-size: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
}
.btn-outline-light {
    color: #848484;
}
.btn-lg, .btn-group-lg > .btn {
    padding: 10px 25px;
    font-size: 16px;
    line-height: 1.5;
}
.btn-md {
    padding: 10px 20px;
    font-size: 15px;
}
.btn-xs {
    padding: 8px 16px;
    font-size: 13px;
}
.radius-0 {
    border-radius: 0 !important;
}
.dropdown-item {
    font-size: 14px;
}
.btn-warning {
    color: #fff;
    &:hover {
        color: #fff;
    }
}
.btn-outline-warning {
    &:hover {
        color: #fff;
    }
}
.form-control {
    border-radius: 4px;
    border-color: #ebebee;
    font-size: 13px;
    box-shadow: none;
    &:focus {
        box-shadow: none;
        border-color: $primary-color;
    }
}
.form-group {
    .form-text {
        margin-top: 10px;
        font-size: 12px;
    }
    > label {
        text-transform: capitalize;
        font-weight: 500;
    }
}
.form-check-input {
    margin-top: 2px;
}
.custom-control-label {
    padding-top: 4px;
}
.input-group-text {
    color: #fff;
    background-color: $primary-color;
    border-radius: 0;
} 
.loader {
    top: 20px;
    position: absolute;
    left: 60%;
    text-align: center;
}
/* End Default style */
 
/* Helper class */
.fs-10 {
    font-size: 10px;
}
.fs-11 {
    font-size: 11px;
}
.fs-12 {
    font-size: 12px;
}
.fs-13 {
    font-size: 13px;
}
.fs-14 {
    font-size: 14px;
}
.fs-15 {
    font-size: 15px;
}
.fs-18 {
    font-size: 18px;
}
.fs-20 {
    font-size: 20px;
}
.fs-22 {
    font-size: 22px; 
}
.fs-40 {
    font-size: 40px;
}
.fs-50 {
    font-size: 50px;
}
.fw-400 {
    font-weight: 400;
}
.fw-500 {
    font-weight: 500;
}
.fw-600 {
    font-weight: 600;
}
.mr-5 {
    margin-right: 5px !important;
}
.mr-10 {
    margin-right: 10px;
}
.mb-20 {
    margin-bottom: 20px !important;
}
.mt-40 {
    margin-top: 40px;
}
.mt-minus-1 {
    margin-top: -1px;
}
.mt-minus-2 {
    margin-top: -2px;
}
.mt-minus-3 {
    margin-top: -3px;
}
.mt-minus-4 {
    margin-top: -4px;
}
.mt-minus-5 {
    margin-top: -5px;
}
.pr-70 {
    padding-right: 70px;
}
.p-30 {
    padding: 30px;
}
.fr {
    float: right;
}
.wh-13 {
    width: 13px;
    height: 13px;
    margin-top: -2px;
}
.wh-15 {
    width: 15px;
    height: 15px;
}
.wh-30 {
    width: 30px;
    height: 30px;
}
.wh-35 {
    width: 35px;
    height: 35px;
}
.wh-40 {
    width: 40px;
    height: 40px;
}
.w-80 {
    width: 80px;
}
.w-full {
    width: 100%;
}
.mw-350 {
    max-width: 350px;
    margin-left: auto;
    margin-right: auto;
}
.relative {
    position: relative;
}
.border {
    border: 1px solid $border-color;
}
.black-text {
    color: $template-text-color !important;
}
.primary-text {
    color: $primary-color !important;
}
.purple-text {
    color: $purple-color !important;
}
.success-text {
    color: $success-color !important;
}
.danger-text {
    color: $danger-color !important;
}
.theme-color {
    color: $primary-color;
}
.danger-color {
    color: $danger-color;
}
.global-color {
    color: $global-color;
}
.gray-color {
    color: $gray-color;
}
.light-blue-bg {
    background: $light-blue-bg;
}
.purple-bg {
    background: $purple-color;
}
.primary-bg {
    background: $primary-color;
}
.success-bg {
    background: $success-color;
}
.danger-bg {
    background: $danger-color;
}
.theme-bg {
    background-color: $primary-color !important;
}
.right {
    right: 0;
} 
.bottom {
    bottom: 0;
}
.right-3 {
    right: 3px;
} 
.bottom-2 {
    bottom: 2px;
}
.bottom-3 {
    bottom: 10px;
}
.d-table {
    width: 100%;
    height: 100%;
}
.d-tablecell {
    display: table-cell;
    vertical-align: middle;
}
.line-height-1 {
    line-height: 1;
}
.bg-white {
    background-color: #fff !important;
}
.border-radius-fl-item {
    .page-item {
        &:first-child {
            .page-link {
                border-top-left-radius: 15px;
                border-bottom-left-radius: 15px;
            }
        }
        &:last-child {
            .page-link {
                border-top-right-radius: 15px;
                border-bottom-right-radius: 15px;
            }
        }
    }
}
.height-408 {
    height: 408px;
    overflow-y: auto;
}
.height-310 {
    height: 310px;
    overflow-y: auto;
}
.height-500 {
    height: 500px;
    overflow-y: auto;
}
.rounded {
    border-radius: 50px !important;
}
.alert-light {
    background-color: #f9f9f9;
    border-color: #f9f9f9;
}
.alert:last-child {
    margin-bottom: 0;
}
.alert-dismissible .close {
    padding: 0.60rem 1.20rem; 
}
.white-color {
    color: #fff;
}
.card-img-overlay {
    background: rgba(41, 98, 255, .5);
    .card-title  {
        color: #fff !important;
    }
}
.alert-success {
    p {
        color: #155724;
    }
}
.dropdown-menu {
    border-radius: 0;
    .dropdown-item {
        font-size: 13px;
        color: #797979;
    }
}
.badge-warning {
    color: #fff;
}
.btn-primary {
    background-color: $primary-color;
    border-color: $primary-color;
    &:hover {
        background-color: $primary-color;
        border-color: $primary-color;
        opacity: .7;
    }
}
.btn-outline-primary {
    color: $primary-color;
    border-color: $primary-color;
    &:hover {
        background-color: $primary-color;
        border-color: $primary-color;
    }
}
/* End helper class */

/* Main content style */
.main-content {
    width: 100%;
    margin-top: 62px;
    transition: all .50s ease-in-out;
    -webkit-transition: all .50s ease-in-out;
    padding: 30px 30px 0;
    position: relative;
    min-height: calc(100vh - 62px);
    padding-left: 250px;
    overflow: hidden;
}
/* End main content style */

/* Dashboard landing page v.1 style */
.main-content-header {
    margin-bottom: 20px;
    border-bottom: 1px solid $border-color;
    padding-bottom: 10px;
    h1 {
        margin: 0;
        font-size: 18px;
        padding-right: 15px;
    }
    .breadcrumb {
        .breadcrumb-item {
            margin-top: 1px;
        }
        padding: 0;
        margin-bottom: 0;
        background-color: transparent;
        a {
            color: $template-text-color;
            &:hover {
                color: $primary-color;
            }
        }
    }
}
.single-sales-card {
    .card-body {
        padding: 0 !important;
        .icon {
            background: rgba(0,0,0,0.1);
            color: #fff;
            width: 80px;
            height: 80px;
            padding: 22px;
            border-radius: 4px 0 0 4px; 
        }
        .content {
            p {
                color: #fff;
                line-height: 1;
            }
        }
    }
}
.card {
    box-shadow: 2px 3px 10px 0px rgba(119,119,119,0.1);
    border-radius: 4px;
    border: initial;
    .card-body {
        padding: 20px;
    }
    .card-header {
        background-color: transparent;
        padding: 0;
        border-bottom: 1px solid $border-color;
        position: relative;
        margin-bottom: 20px;
        &::before {
            content: '';
            background: $primary-color;
            height: 1px;
            width: 30px;
            position: absolute;
            left: 0;
            bottom: -1px;
        }
        .day {
            background-color: $primary-color;
            position: absolute;
            bottom: 2px;
            right: 0;
            color: #fff;
            display: inline-block;
            font-size: 10px;
            padding: 5px 10px 5px 22px;
            -webkit-clip-path: polygon(100% 0, 100% 50%, 100% 100%, 0% 100%, 16% 50%, 0% 0%);
            clip-path: polygon(100% 0, 100% 50%, 100% 100%, 0% 100%, 16% 50%, 0% 0%);
        }
    }
    .card-title {
        font-size: 16px;
        padding-bottom: 10px; 
        padding: 0;
        color: #323332;
    }
    .header {
        padding: 0.75rem 1.25rem;
        margin-bottom: 0;
        color: inherit;
        background-color: rgba(0, 0, 0, 0.03);
        border-bottom: 1px solid rgba(0, 0, 0, 0.125);
        font-weight: 600;
        &::before {
            right: 0;
            margin: auto;
        }
    }
    .content {
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        max-width: 120px;
    }
}
.card-dropdown {
    .btn {
        border-radius: 30px;
        font-size: 15px;
        padding: 0;
        line-height: 25px;
        background: #fff;
        color: $primary-color;
        height: 25px;
        width: 25px;
        margin-top: -5px;
        .icon {
            width: 18px;
            height: 18px;
        }
        &:hover {
            background: $primary-color;
            color: #fff;
        }
        &:after {
            display: none;
        }
    }
    .dropdown-menu.show {
        left: auto !important;
        right: 0;
        transform: inherit !important;
        top: 24px !important;
        border-radius: 0;
    }
    .dropdown-item {
        font-size: 13px;
        padding: 0.40rem 1rem;
        white-space: pre-wrap;
        font-weight: 300;
    }
    .dropdown-item.active, .dropdown-item:active {
        color: #16181b;
        background-color: #f8f9fa;
    }
}

.card-header {
    .select-month-form {
        position: absolute;
        right: 0;
        bottom: -1px;
        .form-control {
            height: 30px;
        }
    }
}

.hover-card {
    -webkit-transition: all .30s ease;
    -o-transition: all .30s ease;
    -moz-transition: all .30s ease;
    transition: all .30s ease;
    &:hover {
        -webkit-transform: translateY(-4px) scale(1.01);
        -moz-transform: translateY(-4px) scale(1.01);
        -ms-transform: translateY(-4px) scale(1.01);
        -o-transform: translateY(-4px) scale(1.01);
        transform: translateY(-4px) scale(1.01);
        box-shadow: 0 15px 25px rgba(60, 50, 100, .1);
    }
}

/* stats card */
.stats-card {
    background-color: #fff;
    padding: 20px;
    position: relative;
    border-radius: 4px;
    box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04);
    h3 {
        margin: 0;
        font-size: 22px;
        .icon {
            margin-left: 5px;
            width: 18px;
            height: 18px;
            margin-top: -4px;
        }
    }
    p {
        margin: 5px 0 0;
        line-height: 1
    }
    i {
        position: absolute;
        right: 0;
        top: 0;
        color: #fff;
        height: 50px;
        width: 50px;
        border-radius: 30px 0 30px 30px;
        transition: .5s;
        text-align: center;
        line-height: 50px;
        font-size: 25px;
    }
    &:hover i {
        height: 100%;
        border-radius: 0 4px 4px 0;
        width: 70px;
        line-height: 85px;
        font-size: 35px;
    }
    &:hover .icon-hover {
        height: 60px;
        width: 60px;
        line-height: 60px;
        border-radius: 30px 0 30px 30px;
        font-size: 30px;
    }
    .stats-icon {
        position: absolute;
        right: 0;
        top: 0;
        color: #fff;
        height: 50px;
        width: 50px;
        padding: 10px;
        border-radius: 30px 0 30px 30px;
        transition: .5s;
    }
    &:hover .stats-icon {
        height: 100%;
        border-radius: 0 4px 4px 0;
        width: 70px;
        padding: 15px;
    }
}
.purple-card {
    h3 {
        .icon {
            color: $purple-color;
        }
    }
    .stats-icon {
        background: $purple-color;
    }  
    i {
        background: $purple-color;
    }
    .progress {
        .progress-bar {
            background-color: $purple-color !important;
        }
    }
}
.light-blue-card {
    h3 {
        .icon {
            color: $light-blue-bg;
        }
    }
    .stats-icon {
        background: $light-blue-bg;
    }  
    i {
        background: $light-blue-bg;
    }
    .progress {
        .progress-bar {
            background-color: $light-blue-bg !important;
        }
    }
}
.success-card {
    h3 {
        .icon {
            color: $success-color;
        }
    }
    .stats-icon {
        background: $success-color;
    }  
    i {
        background: $success-color;
    }
    .progress {
        .progress-bar {
            background-color: $success-color !important;
        }
    }
}
.danger-card {
    h3 {
        .icon {
            color: $danger-color;
        }
    }
    .stats-icon {
        background: $danger-color;
    }  
    i {
        background: $danger-color;
    }
    .progress {
        .progress-bar {
            background-color: $danger-color !important;
        }
    }
}
/* End stats card */

.top-rated-products {
    list-style-type: none; 
    padding: 0;
    margin: 0;
    .single-product {
        border-bottom: 1px solid $border-color;
        position: relative;
        padding-left: 80px;
        padding-top: 15px;
        padding-bottom: 15px;
        padding-right: 45px;
        img {
            border: 1px solid #f3f3f3;
            width: 65px;
            height: 65px;
            position: absolute;
            left: 0;
            top: 14px;
        }
        .product-title {
            font-size: 13px;
            color: $template-text-color;
            font-weight: 500;
            line-height: 1;
            &:hover {
                color: $primary-color;
            }
        }
        p {
            margin: 2px 0;
        }
        .rating {
            line-height: 0;
            span {
                color: #ffce00;
                margin-right: 5px;
            }
        }
        .view-link {
            position: absolute;
            top: 30px;
            right: 10px;
            border: 1px solid $primary-color;
            color: $primary-color;
            height: 30px;
            width: 30px;
            text-align: center;
            display: inline-block;
            line-height: 30px;
            border-radius: 30px;
            &:hover {
                background: $primary-color;
                color: #fff;
            }
        }
    }
}
.tickets-lists {
    list-style-type: none;
    padding: 0;
    margin: 0;
    height: 365px;
    overflow-y: auto;
    li {
        border-bottom: 1px solid $border-color;
        position: relative;
        padding-left: 65px;
        padding-top: 15px;
        padding-bottom: 15px;
        padding-right: 40px;
        img {
            border: 1px solid #f3f3f3;
            width: 50px;
            height: 50px;
            position: absolute;
            left: 0;
            top: 11px;
        }
        .product-title {
            font-size: 13px;
            color: $template-text-color;
            font-weight: 500;
            display: inline-block;
            margin-bottom: 3px;
            &:hover {
                color: $primary-color;
            }
        }
        .date, .time {
            color: #797979;
            .icon {
                width: 10px;
                height: 10px;
            }
        }
    }
}
.height-365 {
    height: 365px;
    overflow-y: auto;
}
.product-categories {
    list-style-type: none;
    padding: 0;
    margin: 0;
    li {
        line-height: 25px;
        position: relative;
        padding-left: 16px;
        &::before {
            content: '';
            border: 1.4px solid $primary-color;
            width: 10px;
            height: 10px;
            position: absolute;
            left: 0;
            top: 7px;
            border-radius: 50px;
        }
        a {
            color: #797979;
            &:hover {
                color: $primary-color;
            }
        }
    }
}
.table {
    .bg-none {
        background: none;
    }
    .border-none {
        th {
            border-top: none;
            padding-top: 0;
        }
    }
    th {
        font-size: 15px;
    }
    th, td {
        border-bottom: 1px solid #eee;
    }
    td {
        color: #797979;
    }
    .badge {
        font-weight: 500;
        padding: 5px 7px;
    }
    a {
        color: #797979;
        &:hover {
            color: $primary-color;
        }
    }
    tr.active {
        background: #f3f6f9;
    }
}
 
.table-hover {
    tbody {
        tr {
            &:hover {
                background-color: #f3f6f9;
            }
        }
    }
}
.table-dark {
    thead {
        background: #212529;
    }
    td {
        color: #fff;
    }
}
/* End dashboard landing page v.1 style */

/* Search result style */
.search-result {
    .single-product {
        border: 1px solid $border-color;
        padding-left: 100px;
        padding-top: 20px;
        padding-bottom: 20px;
        padding-right: 45px;
        margin-top: 20px;
        border-radius: 6px;
        img {
            left: 20px;
            top: 18px;
        }
        .view-link {
            top: 35px;
            right: 20px;
        }
    }
}
.page-link {
    color: $primary-color;
    &:hover {
        color: #000;
    }
}
.page-item.active .page-link {
    background-color: $primary-color;
    border-color: $primary-color;
}
/* End search result style */

/* Notifications page style */
.notifications-card {
    .noti-card-title {
        background: #fff;
        margin: 0;
        padding: 15px 20px;
        border-top: 1px solid $border-color;
        border-left: 1px solid $border-color;
        border-right: 1px solid $border-color;
        font-size: 15px;
    }
    .list-group {
        .list-group-item {
            border: 1px solid $border-color;
            padding: 15px 15px 15px 40px;
            position: relative;
            font-size: 13px;
            &:first-child {
                border-top-left-radius: 0;
                border-top-right-radius: 0;
            }
            a {
                font-weight: 500;
                color: $primary-color;
                &:hover {
                    text-decoration: underline;
                }
            }
            .icon {
                position: absolute;
                left: 15px;
                top: 16px;
                width: 16px;
                height: 16px;
            }
            span {
                position: absolute;
                left: 15px;
                top: 15px;
                font-size: 18px;
            }
            .time {
                position: absolute;
                right: 15px;
                top: 17px;
                color: #a1aab2;
                font-size: 13px;
            }
            ul {
                list-style-type: none;
                margin: 0;
                padding: 0;
                li {
                    display: inline-block;
                    margin-right: 5px;
                    margin-top: 5px;
                }
            }
            .content {
                padding-top: 10px;
            }
            .media {
                padding: 15px;
                border: 1px solid $border-color;
                border-radius: 5px;
            }
        }
    }
}
.status {
    position: absolute;
    height: 10px;
    width: 10px;
    border-radius: 50%;
}
.online {
    background:#42b72a;
}
.ofline {
    background: #f62d51;
}
.away {
    background: #ffbc34; 
}
/* End Notifications page style */

/* Todo list style */
.todo-list {
    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        li {
            position: relative;
            border: 1px solid $border-color;
            padding: 15px 40px 15px 45px;
            border-radius: 10px;
            margin-top: 10px;
            .task {
                font-size: 14px;
            }
            .completed {
                text-decoration: line-through;
                color: cadetblue;
            }
        }
        .close-btn {
            background-color: transparent;
            color: $danger-color;
            position: absolute;
            right: 15px;
            top: 15px;
            padding: 0;
            height: 22px;
            width: 22px;
            font-size: 9px;
            border-radius: 50%;
            line-height: 22px;
        }   
    }
    .add-new-task {
        position: relative;
        margin-top: 20px;
        margin-bottom: 20px;
        .form-control {
            border-color: $border-color;
            border-radius: 0;
            background: #eef5f9;
            height: 42px;
            box-shadow: none;
        }
        .btn-primary {
            position: absolute;
            top: 0;
            right: 0;
            border-radius: 0;
            height: 42px;
        }
    }
}
.control {
    display: block;
    position: absolute;
    cursor: pointer;
    left: 15px;
    top: 12px;
    input {
        position: absolute;
        z-index: -1;
        opacity: 0;
    }
    .control-indicator {
        position: absolute;
        top: 2px;
        left: 0;
        height: 22px;
        width: 22px;
        border: 1px solid #e6e6e6;
        border-radius: 50%;
    }
}
.control input:checked ~ .control-indicator {
    background: $primary-color;
}
.control-indicator:after {
    content: '';
    position: absolute;
    display: none;
}
.control input:checked ~ .control-indicator:after {
    display: block;
}
.control-checkbox .control-indicator:after {
    left: 7px;
    top: 3px;
    width: 6px;
    height: 11px;
    border: 1px solid #e6e6e6;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg)
}
/* End Todo list style */ 
 
/* Signup page style */
.auth-main-content {
    height: 100vh;
    padding: 0 15px;
}
.auth-box {
    max-width: 750px;
    margin: 30px auto;
    background: #fff; 
    border-radius: 10px;
    position: relative;
    box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04);
    padding-left: 15px;
    padding-right: 15px;
    &::before {
        content: '';
        position: absolute;
        background: $border-color;
        height: 100%;
        width: 1px;
        left: 0;
        right: 0;
        margin: auto;
        top: 0;
    } 
}
.form-left-content {
    padding: 50px 30px 50px 30px;
    .auth-logo {
        text-align: center;
        font-size: 25px;
        color: $primary-color;
        line-height: 1;
    }
    .login-links {
        a {
            display: block;
            margin-top: 15px;
            line-height: 1;
            padding: 8px 15px;
            border-radius: 30px;
            text-align: center;
            .icon {
                width: 15px;
                height: 15px;
                margin-right: 5px;
                margin-top: -2px;
            }
            span {
                padding-right: 5px;
            }
        }
        .fb {
            background: #3B5998;
            color: #fff;
            border: 1px solid #3B5998;
            &:hover {
                border-radius: 0;
            }
        }
        .twi {
            border: 1px solid #3CF; 
            background: #3CF;
            color: #fff;
            &:hover {
                border-radius: 0;
            }
        }
        .ema {
            border: 1px solid #DC4A38;
            background: #DC4A38;
            color: #fff;
            &:hover {
                border-radius: 0;
            }
        }
        .linkd {
            border: 1px solid #007bb6;
            background: #007bb6;
            color: #fff;
            &:hover {
                border-radius: 0;
            }
        }
    }
}
.form-content {
    padding: 50px 30px 50px 30px;
    .heading {
        margin: 0 0 20px;
        position: relative;
        font-size: 18px;
        border-bottom: 1px solid $border-color;
        padding-bottom: 8px;
        font-weight: 600;
        &::before {
            content: '';
            background: $primary-color;
            height: 1px;
            width: 30px;
            position: absolute;
            left: 0;
            bottom: -1px;
        }
    }
    .form-control {
        height: 35px;
        border-radius: 30px; 
        border: 1px solid #ebebeb;
    }
    .btn-primary {
        border-radius: 30px;
        font-size: 13px;
        padding: 10px 20px;
        line-height: 1;
        width: 100%;
    }
    .fp-link {
        display: block;
        margin-top: 15px;
        max-width: 120px;
        margin-left: auto;
        margin-right: auto;
        color: $template-text-color;
        &:hover {
            color: $primary-color;
            text-decoration: underline;
        }
    }
}
.auth-bg-image {
    background-image: url('../img/auth-bg-image.jpg');
    background-position: center center;
    background-attachment: fixed;
    background-size: cover;
}
/* End signup page style */

/* Notes page style */
.notes-card {
    position: relative;
    button {
        background: #f75d81;
        border-color: #f75d81;
        position: absolute;
        top: 0;
        right: 0;
        padding: 5px;
        font-size: 10px;    
        border-radius: 0;
    }
}
.notes-content {
    .form-control {
        border-radius: 0;
        border-color: $border-color;
        box-shadow: none;
        padding: 15px;
        line-height: 26px; 
    }
    form {
        position: relative;
        .btn {
            position: absolute;
            top: 0;
            right: 0;
        }
    }
}
/* End notes page style */

/* Map style */
.map-content {
    width: 100%;
    height: 500px;
}
/* End map style */

/* Calendar style */
.calendar-content {
    width: 100% !important;
    margin-top: 20px;
    .Cal__Day__month {
        top: 12px !important;
    }
    .Cal__Day__day {
        top: 6px !important;
        font-size: 15px !important;
    }
    .Cal__Today__chevron {
        margin-left: 35px !important;
    }
    .Cal__Header__date {
        font-size: 20px;
        color: #fff;
        text-align: center;
        font-weight: 600;
    }
    .Cal__Header__root {
        min-height: 100%;
        padding: 30px 20px 20px;
    }
}
/* End Calendar style */

/* List group */
.card-header + .list-group .list-group-item:first-child {
    border-top: 1px solid #ebebeb;
    border-radius: 0;
}
.list-group-item:last-child {
    border-radius: 0;
}
/* End List group */

/* Modal style */
.modal-dialog {
    .modal-content {
        border-radius: 0;
        .modal-header {
            padding: 20px 30px;
            .modal-title {
                font-size: 22px;
                line-height: 1;
            }
        }
        .modal-body {
            padding: 30px;
        }
        .modal-footer {
            padding: 20px 30px;
        }
    }
    p {
        line-height: 25px;
    }
}
.modal-90w {
    width: 90%;
    max-width: none!important;
}
/* End Modal style */

/* Tab style */
.tab-content {
    p {
        margin-bottom: 10px;
        &:last-child {
            margin: 0;
        }
    }
}
.tabs-style-one {
    .tab-content {
        padding: 20px;
        border-bottom: 1px solid $border-color;
        border-left: 1px solid $border-color;
        border-right: 1px solid $border-color;
    }
    .nav-tabs {
        .nav-link {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            color: #495057;
        }
    }
}
.tabs-style-two {
    .tab-content {
        padding: 20px;
        border-bottom: 1px solid $border-color;
        border-left: 1px solid $border-color;
        border-right: 1px solid $border-color;
    }
    .nav-tabs {
        .nav-link {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            color: #495057;
        }
        .nav-link.active, .nav-item.show .nav-link {
            color: #fff;
            background-color: $primary-color;
            border-color: $primary-color;
        }
    }
}
.tabs-style-three {
    .tab-content {
        padding: 20px;
        border-bottom: 1px solid $border-color;
        border-left: 1px solid $border-color;
        border-right: 1px solid $border-color;
    }
    .nav-tabs {
        .nav-link {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            color: #495057;
            border-top: 2px solid transparent;
            &:hover {
                border-top: 2px solid $primary-color;
                border-bottom-color: #fff;
            }
        }
        .nav-link.active, .nav-item.show .nav-link {
            border-top: 2px solid $primary-color;
        }
    }
}
.pill-tab {
    .nav {
        border-bottom: 1px solid #ebebee;
    }
    .nav-pills {
        .nav-link {
            border-radius: 0;
            color: #495057;
            &:hover {
                color: $primary-color;
            }
        }
        .nav-link.active, .show > .nav-link {
            color: #fff;
            background-color: $pragraph-color;
        }
    }
    .tab-content {
        margin-top: 20px;
    }
}
.vertical-pills-tab {
    .nav-pills {
        .nav-link {
            border-radius: 0;
            color: #495057;
            &:hover {
                color: $primary-color;
            }
        }
        .nav-link.active, .show > .nav-link {
            color: #fff;
            background-color: $primary-color;
        }
    }
}
/* End Tab style */

/* Inbox style */
.inbox-main-sidebar {
    .nav-pills {
        margin-top: 20px;
        .nav-link {
            border-radius: 0px;
            color: #47404f;
            &:hover {
                color: $primary-color;
                background-color: #fff;
            }
        }
        .nav-link.active {
            color: $primary-color;
            background-color: #fff;
        }
    }
}
.inbox-content-wrap {
    background: #fff;
}
textarea.form-control {
    line-height: 22px;
}
.mail-item-nav {
    border-right: 1px solid #eee;
    height: 720px;
    overflow-y: auto;
    .nav-pills {
        .nav-link {
            border-radius: 0;
            border-bottom: 1px solid #eee;
            padding: 20px 15px;
            position: relative;
            overflow: hidden;
            &:hover {
                background-color: #fafafa;
                &::before {
                    content: '';
                    position: absolute;
                    right: -18px;
                    background: $primary-color;
                    height: 15px;
                    width: 40px;
                    -webkit-transform: rotate(90deg);
                    transform: rotate(-45deg);
                    bottom: 0;
                }
            }
            img {
                position: absolute;
            }
            .info {
                padding-left: 40px;
                .name {
                    line-height: 1;
                    color: #70657b;
                    font-size: 12px;
                }
                p {
                    color: #47404f;
                    margin: 6px 0 0;
                    line-height: 1;
                }
                .date {
                    right: 15px;
                    position: absolute;
                    top: 20px;
                    color: #797979;
                    font-size: 10px;
                    line-height: 1;
                }
            }
        }
        .nav-link.active{
            background-color: #fafafa;
            &::before {
                content: '';
                position: absolute;
                right: -18px;
                background: $primary-color;
                height: 15px;
                width: 40px;
                -webkit-transform: rotate(90deg);
                transform: rotate(-45deg);
                bottom: 0;
            }
        }
    }
}
.email-details-warp {
    height: 720px;
    overflow-y: auto;
    padding: 30px 30px 30px 0;
    position: relative;
    p {
        color: #797979;
    }
    .inbox-topbar {
        position: absolute;
        top: 30px;
        right: 30px;
        .btn {
            border-radius: 30px;
            padding: 5px 10px;
            font-size: 10px;
        }
    }
}
/* End Inbox style */

/* Marketing content */
.marketing-content {
    .list {
        position: relative;
        padding-left: 50px;
        margin-bottom: 11px;
        border-bottom: 1px solid #eee;
        padding-bottom: 11px;
        &:last-child {
            margin-bottom: 0;
            border-bottom: none;
            padding-bottom: 0;
        }
        > .icon {
            width: 35px;
            height: 35px;
            color: #fff;
            padding: 8px;
            position: absolute;
            left: 0;
            top: 0;
            border-radius: 2px;
        }
        .fb {
            background: #4267b2;
        }
        .ins {
            background: #A1755C;
        }
        .twi {
            background: #1da1f2;
        }
        .lin {
            background: #0077B5;
        }
        .ytb {
            background: #ff0000;
        }
        h3 {
            margin: 0;
            font-size: 13px;
            a {
                color: #47404f;
                &:hover {
                    color: $primary-color;
                }
            }
        }
        p {
            margin: 7px 0 0;
            line-height: 1;
            font-size: 12px;
        }
        .stats {
            position: absolute;
            right: 0;
            top: 0;
            .icon {
                width: 14px;
                height: 14px;
                margin-right: 8px;
            }
        }
    }
}
/* End Marketing content */

/* New customer card */
.new-customer {
    position: relative;
    padding-left: 52px;
    padding-top: 5px;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #eee;
    &:last-child {
        margin-bottom: 0;
    }
    .profile {
        position: absolute;
        left: 0;
        top: 0;
        img {
            width: 40px;
            height: 40px;
        }
    }
    .price {
        position: absolute;
        right: 0;
        top: 8px;
        font-size: 14px;
        font-weight: 500;
    }
}
/* End new customer card */

/* Search contact */
.search-contact {
    padding: 15px;
    border-bottom: 1px solid #eee;
    .form-control {
        height: 41px;
    }
}
.chat-header {
    border-bottom: 1px solid #eee;
    padding: 8px 0;
    margin-right: 30px;
}
.chat-details-warp {
    padding: 30px 30px 110px 0;
}
.chat-list-right {
    position: relative;
    padding-right: 60px;
    margin-bottom: 20px;
    > img {
        position: absolute;
        right: 0;
        top: 0;
    }
    .chat-details {
        background: #fafafa;
        position: relative;
        padding: 20px 20px 10px;
        &::before {
            position: absolute;
            content: "";
            top: 14px;
            right: -5px;
            background: 0 0;
            border-style: solid;
            border-width: 0 12px 12px 0;
            border-color: transparent transparent #fafafa;
            -webkit-transform: rotate(-135deg);
            transform: rotate(-135deg);
        }
        .name {
            font-size: 15px;
        }
        .date {
            position: absolute;
            top: 20px;
            right: 20px;
            color: #797979;
            font-size: 12px;
        }
    }
}
.chat-list-left {
    position: relative;
    padding-left: 60px;
    margin-bottom: 20px;
    > img {
        position: absolute;
        left: 0;
        top: 0;
    }
    .chat-details {
        background: #fafafa;
        position: relative;
        padding: 20px 20px 10px;
        &::before {
            position: absolute;
            content: "";
            top: 14px;
            left: -5px;
            background: 0 0;
            border-style: solid;
            border-width: 0 12px 12px 0;
            border-color: transparent transparent #fafafa;
            -webkit-transform: rotate(-135deg);
            transform: rotate(45deg);
        }
        .name {
            font-size: 15px;
        }
        .date {
            position: absolute;
            top: 20px;
            right: 20px;
            color: #797979;
            font-size: 12px;
        }
    }
}
.chat-box {
    background: #fff;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding-right: 30px;
    padding-bottom: 30px;
}
/* End Search contact */

/* Order stats card */
.order-stats-card {
    position: relative;
    h3 {
        margin: 0;
        font-size: 13px;
    }
}
.order-number-stats {
    text-align: center;
    .number {
        font-weight: 500;
        font-size: 18px;
    }
    p {
        margin: 0;
        line-height: 1;
    }
}
/* End order stats card */

/* Visitor stats card */
.visitor-stats {
    background-color: #fff;
    padding: 20px;
    position: relative;
    border-radius: 4px;
    box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04);
    text-align: center;
    h3 {
        margin: 0;
        font-size: 22px;
    }
    p {
        margin: 5px 0 10px;
        line-height: 1;
        .icon {
            width: 15px;
            height: 15px;
            margin: 0 5px;
        }
    }
}
/* End visitor stats card */

/* Profile page style */
.profile-header {
    background-color: #fff;
    padding: 30px;
    text-align: center;
    box-shadow: 2px 3px 10px 0px rgba(119, 119, 119, 0.1);
    border-radius: 4px;
    .name {
        margin: 0;
        font-size: 18px;
    }
    p {
        margin: 5px auto 0;
        max-width: 690px;
        line-height: 22px;
    }
    .profile-stats {
        a {
            display: inline-block;
            margin-top: 10px;
            margin-left: 8px;
            margin-right: 8px;
            color: #797979;
            strong {
                color: #000;
            }
            &:hover strong {
                color: $primary-color;
            }
        }
    }
}
.profile-left-content {
    .info {
        list-style-type: none;
        padding: 0;
        margin: 0;
        li {
            line-height: 22px;
            position: relative;
            color: $pragraph-color;
            padding-left: 20px;
            margin-bottom: 5px;
            &:last-child {
                margin-bottom: 0;
            }
            .icon {
                width: 14px;
                height: 14px;
                color: #000;
                position: absolute;
                left: 0;
                top: 3px;            
            }
        }
    }
}
.profile-middle-content {
    background-color: #fff;
    box-shadow: 2px 3px 10px 0px rgba(119, 119, 119, 0.1);
    border-radius: 4px;
}
.post-card {
    padding: 20px;
    border-bottom: 1px solid #eee;
 
    .contents {
        h5 {
            font-size: 15px;
            margin-bottom: 5px;
            line-height: 22px;
            a {
                color: #47404f;
                &:hover {
                    color: $primary-color;
                }
            }
        }
        p {
            margin: 0 0 10px;
        }
    }
    .feed-back {
        a {
            color: #797979;
            margin-right: 15px;
            border-right: 1px solid #eee;
            padding-right: 10px;
            &:hover {
                color: $primary-color;
            }
            &:last-child {
                border-right: none;
                padding-right: 0;
            }
            .icon {
                width: 17px;
                height: 17px;
                margin-right: 5px;
                margin-top: -2px;
            }
        }
    }
}
.profile-right-content {
    .connecting-list {
        height: 255px;
        overflow-x: hidden;
    }
    .list-card {
        border-bottom: 1px solid #eee;
        margin-bottom: 12px;
        padding-bottom: 12px;
        &:last-child {
            margin-bottom: 0;
            padding-bottom: 0;
            border-bottom: none;
        }

        .contents {
            h5 {
                margin: 0 0 8px;
                font-size: 12px;
                a {
                    color: #47404f;
                    &:hover {
                        color: $primary-color;
                    }
                }
            }
            .btn {
                font-size: 10px;
                padding: 5px 12px;
            }
        }
    }
}
/* Profile page style */
.profile-settings-form {
    padding: 30px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 2px 3px 10px 0px rgba(119, 119, 119, 0.1);
    max-width: 800px;
    margin: auto;
}
/* Users card */
.single-user-card {
    background-color: #fff;
    text-align: center;
    border-radius: 4px;
    box-shadow: 2px 3px 10px 0px rgba(119, 119, 119, 0.1);
    img {
        background-color: #e0e0e0;
        height: 120px;
        width: 120px;
    }
    h4 {
        font-size: 18px;
    }
    .social-links {
        a {
            display: inline-block;
            margin: 0 5px;
            color: #797979;
            &:hover {
                color: $primary-color;
            }
            .icon {
                width: 15px;
                height: 15px;
            }
        }
    }
    .user-card-foot {
        list-style-type: none;
        padding: 0;
        margin: 0;
        border-top: 1px solid #eee;
        li {
            display: inline-block;
            display: inline-block;
            width: 33.33%;
            padding-top: 10px;
            padding-bottom: 10px;
            padding-left: 10px;
            padding-right: 10px;
            color: #797979;

            span {
                display: block;
                font-weight: 500;
                font-size: 18px;
                color: #47404f;
            }
        }
        .bor-lr {
            border-left: 1px solid #eee;
            border-right: 1px solid #eee;
        }
    }
}
.octagon-style {
    -webkit-clip-path: polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%);
    clip-path: polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%);
}
.nonagon-style {
    -webkit-clip-path: polygon(50% 0%, 83% 12%, 100% 43%, 94% 78%, 68% 100%, 32% 100%, 6% 78%, 0% 43%, 17% 12%);
    clip-path: polygon(50% 0%, 83% 12%, 100% 43%, 94% 78%, 68% 100%, 32% 100%, 6% 78%, 0% 43%, 17% 12%);
}
.bevel-style {
    -webkit-clip-path: polygon(20% 0%, 80% 0%, 100% 20%, 100% 80%, 80% 100%, 20% 100%, 0% 80%, 0% 20%);
    clip-path: polygon(20% 0%, 80% 0%, 100% 20%, 100% 80%, 80% 100%, 20% 100%, 0% 80%, 0% 20%);
}
/* End Users card */

/* TimeLine Style */
.timeline-centered {
    position: relative;
    margin-bottom: 30px;
    &:before, &:after {
        content: "";
        display: table;
    }
    &:after {
        clear: both;
    }
    &:before {
        content: '';
        position: absolute;
        display: block;
        width: 4px;
        background: #fff;
        left: 50%;
        top: 20px;
        bottom: 20px;
        margin-left: -4px;
    }
    .timeline-card {
        position: relative;
        width: 50%;
        float: right;
        margin-bottom: 40px;
        clear: both;
        &:before, &:after {
            content: " ";
            display: table;
        }
        &:after {
            clear: both;
        }
        &:before, &:after {
            content: " ";
            display: table;
        }
        &:after {
            clear: both;
        }
    }
    .timeline-card.begin {
        margin-bottom: 0;
    }
    .timeline-card.left-aligned {
        float: left;
        .timeline-body {
            margin-left: 0;
            margin-right: -18px;
            .time {
                left: auto;
                right: -100px;
                text-align: left;
            }
            .timeline-icon {
                float: right;
            }
            .timeline-label {
                margin-left: 0;
                margin-right: 70px;
                &:after {
                    left: auto;
                    right: 0;
                    margin-left: 0;
                    margin-right: -9px;
                    -moz-transform: rotate(180deg);
                    -o-transform: rotate(180deg);
                    -webkit-transform: rotate(180deg);
                    -ms-transform: rotate(180deg);
                    transform: rotate(180deg);
                }
            }
        }
    }
    .timeline-card {
        .timeline-body {
            position: relative;
            margin-left: -22px;
            &:before, &:after {
                content: " ";
                display: table;
            }
            &:after {
                clear: both;
            }
            &:before, &:after {
                content: " ";
                display: table;
            }
            &:after {
                clear: both;
            }
            .time {
                position: absolute;
                left: -100px;
                text-align: right;
                > span {
                    display: block;
                }
                > span:first-child {
                    font-size: 15px;
                    font-weight: 500;
                }
                > span:last-child {
                    font-size: 12px;
                    color: #797979;
                }
            }
            .timeline-icon {
                background: #fff;
                color: #737881;
                display: block;
                width: 40px;
                height: 40px;
                border-radius: 30px;
                text-align: center;
                box-shadow: 0 0 0 4px #fff;
                line-height: 40px;
                font-size: 15px;
                float: left;
            }
            .timeline-label {
                position: relative;
                background: #fff;
                box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04);
                padding: 1.7em;
                margin-left: 70px;
                border-radius: 4px;
                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 10px 10px 10px 0;
                    border-color: transparent #fff transparent transparent;
                    left: 0;
                    top: 10px;
                    margin-left: -9px;
                }
                h3 {
                    font-size: 18px;
                    margin-bottom: 5px;
                    line-height: 25px;
                }
                p {
                    margin-top: 5px;
                    margin-bottom: 0;
                }
            }
        }
    }
} 
.timeline-icon.bg-purple {
    background-color: $purple-color !important;
    color: #fff;
    .icon {
        color: #fff;
        width: 20px;
        height: 20px;
        margin-top: -2px;
    }
}
.timeline-icon.bg-primary {
    background-color: $primary-color;
    color: #fff;
    .icon {
        color: #fff;
        width: 20px;
        height: 20px;
        margin-top: -2px;
    }
}
.timeline-icon.bg-secondary {
    background-color: #ee4749;
    color: #fff;
    .icon {
        color: #fff;
        width: 20px;
        height: 20px;
        margin-top: -2px;
    }
}
.timeline-icon.bg-success {
    background-color: $success-color;
    color: #fff;
    .icon {
        color: #fff;
        width: 20px;
        height: 20px;
        margin-top: -2px;
    }
}
.timeline-icon.bg-info {
    background-color: #21a9e1;
    color: #fff;
    .icon {
        color: #fff;
        width: 20px;
        height: 20px;
        margin-top: -2px;
    }
}
.timeline-icon.bg-warning {
    background-color: #fad839;
    color: #fff;
    .icon {
        color: #fff;
        width: 20px;
        height: 20px;
        margin-top: -2px;
    }
}
.timeline-icon.bg-danger {
    background-color: $danger-color;
    color: #fff;
    .icon {
        color: #fff;
        width: 20px;
        height: 20px;
        margin-top: -2px;
    }
}
/* End TimeLine Style */

/* Invoice Template */
.invoice-template {
    background: #fff;
    padding: 20px;

    .invoice-header {
        position: relative;
        border-bottom: 1px solid #eee;
        padding-bottom: 15px;

        h3 {
            margin: 0;
            font-size: 20px;
            text-transform: uppercase;
        }
        p {
            color: #47404f;
            position: absolute;
            top: 0;
            right: 0;
            font-weight: 500;
            font-size: 18px;
        }
    }
}
/* End Invoice Template */

/* Error page design */
.error-content {
    padding: 30px;
    text-align: center;
    height: 100vh;
    .icon {
        width: 50px;
        height: 50px;
        color: $pragraph-color;
    }
    h1 {
        font-size: 100px;
        font-weight: bolder;
    }
    h3 {
        margin: 0;
    }
    p {
        margin: auto;
        max-width: 500px;
    }
    .back-link {
        background-color: $primary-color;
        color: #fff;
        display: inline-block;
        border: 1px solid $primary-color;
        padding: 10px 30px;
        border-radius: 100px;
        margin-top: 30px;
        &:hover {
            background-color: #333;
            border-color: #333;
        }
    }
}
/* End error page design */

/* Map Style */
.map-height-500 {
    height: 500px;
}
.jvectormap-container {
    padding: 20px;
}
/* End Map Style */

/* Apexcharts content */
.apexcharts-content {
    width: 100%;
    .toolbar {
        margin-left: 45px;
        button {
            background: #fff;
            color: #222;
            border: 1px solid #e7e7e7;
            border-bottom: 2px solid #ddd;
            border-radius: 2px;
            padding: 4px 17px;
            cursor: pointer;
        }
        button.active {
            color: #fff;
            background: $primary-color;
            border: 1px solid $primary-color;
            border-bottom: 2px solid $primary-color;
        }
    } 
}
/* End Apexcharts content */

/* Accordion style  */
.custom-accordion {
    .heading {
        margin-bottom: 20px;
        font-size: 20px;
    }
    .accordion__item + .accordion__item {
        border-top: 1px solid #eee;
    }
    .accordion__button {
        position: relative;
        background-color: #fff;
        padding: 20px;
        &:focus {
            outline: 0;
        }
        font-size: 17px;
        margin: 0;
        color: #000000;
        
        &:hover {
            color: $primary-color;
        }
        &:before {
            float: right;
            margin: 6px 0 0;
        }
    }
    .accordion__panel {
        background-color: #fff;
        border-top: 1px solid #eee;
        p {
            margin-bottom: 10px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
/* End accordion style  */

/* Icon preview style */
.icon-preview {
    background-color: #fff;
    border-radius: 4px;
    padding: 20px;
    position: relative;
    span {
        padding-left: 10px;
        font-size: 15px;
        color: #777;
        position: absolute;
        margin-top: 2px;
    }
}
/* End Icon preview style */

/* Switch Button */
.side-menu-switch {
    position: absolute;
    top: 92px;
    right: 30px;
    z-index: 5;
    .switch {
        position: relative;
        display: inline-block;
        width: 45px;
        height: 26px;
        input { 
            display:none;
        }
        .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #fff;
            -webkit-transition: .4s;
            transition: .4s;
            &:before {
                position: absolute;
                content: "";
                height: 20px;
                width: 20px;
                left: 4px;
                background-color: #2962ff;
                top: 3px;
                transition: .4s;
                -webkit-transition: .4s;
            }
        }
        input.light:checked + .slider {
            background-color: #2c2f48;
            &:before {
                background-color: #fff;
            }
        }
        input:checked + .slider:before {
            -webkit-transform: translateX(18px);
            -ms-transform: translateX(18px);
            transform: translateX(18px);
        }    
        .slider.round {
            border-radius: 30px;
            box-shadow: 0 0 8px #ddd;
        }
        .slider.round:before {
            border-radius: 50%;
        }
    }
}
/* End Switch Button */

/* Pricing Table style */
.pricing-table {
    background: #fff;
    border-radius: 4px;
    text-align: center;
    overflow: hidden;
    position: relative;
    transition: all 0.3s ease 0s;
    svg {
        display: block;
        margin-left: -1px;
    }
    .price {
        position: absolute;
        top: 0;
        color: #fff;
        font-size: 55px;
        font-weight: bold;
        text-align: center;
        width: 100%;
        margin: 0;
        top: 50%;
        transform: translateY(-70%);
        -webkit-transform: translateY(-70%);
        span {
            display: block;
            font-size: 15px;
            font-weight: 300;
            padding-left: 60px;
        }
    }
    .pricing-content {
        padding: 40px 0 30px;
        position: relative;
    }
    .title {
        font-size: 25px;
        font-weight: 600;
        text-transform: uppercase;
        margin: 0 0 10px 0;
    }
    .pricing-content {
        ul {
            padding: 0;
            margin: 20px 0;
            list-style-type: none;
            li {
                font-size: 15px;
                line-height: 40px;
                border-top: 1px solid #eee;
                color: #656565;
            }
        }
    }
    .signup {
        display: inline-block;
        padding: 8px 50px;
        border-radius: 20px;
        font-size: 18px;
        color: #fff;
        text-transform: uppercase;
        position: relative;
        transition: all 0.3s ease 0s;
    }
}
/* End Pricing Table style */

/* Footer Style */
.footer {
    background: #fff;
    padding: 15px 30px;
    text-align: center;
    -webkit-clip-path: polygon(1% 0, 99% 0, 100% 100%, 0% 100%);
    clip-path: polygon(1% 0, 99% 0, 100% 100%, 0% 100%);
    border-radius: 30px 30px 0 0;
    p {
        margin: 0;
        color: #5a5151;
        a {
            color: $primary-color;
            &:hover {
                text-decoration: underline;
            }
        }
    }
}
/* End Footer Style */
 
@media (min-width: 576px) {
    .card-columns {
        -webkit-column-count: 3;
        column-count: 3;
        grid-column-gap: 1.25rem;
        -webkit-column-gap: 1.25rem;
        column-gap: 1.25rem;
        orphans: 1;
        widows: 1;
    }
}

.react-calendar {
    width: 100%;
    border: 1px solid #eee;
    button {
        border: 1px solid #eee;
    }
    .react-calendar__tile {
        border: 1px solid #eee;
        padding: 30px 10px;
        font-size: 17px;
    }
    .react-calendar__navigation {
        button {
            font-size: 17px;
            font-weight: bold;
        }
    }
    .react-calendar__month-view__weekdays__weekday {
        font-size: 14px;
    }
}